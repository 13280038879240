"use client";
import React, { ReactNode, useEffect, useRef } from "react";
import styles from "./NomNomNom.module.scss";

interface NomNomNomParallaxProps {
  background: ReactNode;
  card: ReactNode;
  height?: string;
}

const NomNomNomParallax: React.FC<NomNomNomParallaxProps> = ({
  background,
  card,
}) => {
  const backgroundRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (backgroundRef.current) {
        const { top } = backgroundRef.current.getBoundingClientRect();
        const speed = 0.1;
        backgroundRef.current.style.transform = `translateY(${top * speed}px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className={styles.parallax} style={{ height: "800px" }}>
      <div className={styles.background}>{background}</div>
      <div ref={backgroundRef} className={styles.cardParallaxContainer}>
        {card}
      </div>
    </section>
  );
};

export default NomNomNomParallax;
