import Typography from "@/components/typography/Typography";
import styles from "./BurgerCarouselItem.module.scss";

interface BurgerCarouselItemProps {
  title: string;
  imageUrl: string;
}

const BurgerCarouselItem: React.FC<BurgerCarouselItemProps> = ({
  title,
  imageUrl,
}) => {
  return (
    <div className={styles.carouselItem}>
      <div
        className={styles.imageCard}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <Typography tag="h3" variant="h3" className={styles.burgerTitle}>
        {title}
      </Typography>
    </div>
  );
};

export default BurgerCarouselItem;
