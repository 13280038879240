"use client";
import React, { ReactNode, useEffect, useRef } from "react";
import styles from "./JoinTheFamily.module.scss";

interface NomNomNomParallaxProps {
  imageContainer: ReactNode;
  content: ReactNode;
}

const JoinTheFamilyParallax: React.FC<NomNomNomParallaxProps> = ({
  imageContainer,
  content,
}) => {
  const backgroundRef = useRef<HTMLDivElement | null>(null);
  const imageContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth > 768) {
        if (backgroundRef.current) {
          const { top } = backgroundRef.current.getBoundingClientRect();
          const speed = 0.05;
          backgroundRef.current.style.transform = `translateY(${
            top * speed
          }px)`;
        }

        if (imageContainerRef.current) {
          const { top } = imageContainerRef.current.getBoundingClientRect();
          const speed = 0.05;
          imageContainerRef.current.style.transform = `translateY(-${
            top * speed
          }px)`;
        }
      } else {
        if (backgroundRef.current) {
          backgroundRef.current.style.transform = "translateY(0px)";
        }
        if (imageContainerRef.current) {
          imageContainerRef.current.style.transform = "translateY(0px)";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className={styles.parallax}>
      <div ref={imageContainerRef} className={styles.imageParallaxContainer}>
        {imageContainer}
      </div>
      <div ref={backgroundRef} className={styles.contentParallaxContainer}>
        {content}
      </div>
    </section>
  );
};

export default JoinTheFamilyParallax;
