"use client";

import { useState, useEffect, useMemo, useCallback } from "react";
import Image, { StaticImageData } from "next/image";
import styles from "./ImageContainer.module.scss";
import classNames from "classnames";

export type PlaceholderValue = "blur" | "empty" | `data:image/${string}`;

interface ImageContainerProps {
  images: string[] | StaticImageData[];
  className?: string;
  imageClassName?: string;
  sizes?: string;
  priorityStrategy?: "All" | "first" | "none";
  placeholder?: PlaceholderValue | undefined;
}

export const SLIDE_INTERVAL_MS = 3000;

const ImageContainer: React.FC<ImageContainerProps> = ({
  images,
  className,
  imageClassName,
  sizes = "500px",
  priorityStrategy = "none",
  placeholder,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const setNextImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images.length]);

  useEffect(() => {
    const interval = setInterval(setNextImage, SLIDE_INTERVAL_MS);
    return () => clearInterval(interval);
  }, [setNextImage]);

  const renderedImages = useMemo(
    () =>
      images.map((image, index) => {
        const priority =
          priorityStrategy === "All" ||
          (priorityStrategy === "first" && index === 0);

        return (
          <Image
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            className={classNames(styles.image, imageClassName, {
              [styles.active]: index === currentImageIndex,
            })}
            style={{
              zIndex: images.length - index,
            }}
            sizes={sizes}
            fill
            priority={priority}
            placeholder={placeholder}
          />
        );
      }),
    [
      images,
      currentImageIndex,
      imageClassName,
      sizes,
      priorityStrategy,
      placeholder,
    ]
  );

  return (
    <div className={classNames(className, styles.imageContainer)}>
      {renderedImages}
    </div>
  );
};

export default ImageContainer;
