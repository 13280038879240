"use client";

import { useRef } from "react";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Perspective, Arrow, AutoPlay } from "@egjs/flicking-plugins";
import classNames from "classnames";
import { ChevronIcon } from "@/components/svgs";
import BurgerCarouselItem from "./BurgerCarouselItem";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/arrow.css";
import styles from "./BurgerCarousel.module.scss";

type PromotionalCarouselItem = {
  name: string;
  imageUrl: string;
};

interface PromotionalCarouselProps {
  items: PromotionalCarouselItem[];
}

const PromotionalCarousel: React.FC<PromotionalCarouselProps> = ({ items }) => {
  const flickingRef = useRef<Flicking | null>(null);

  const autoplayPlugin = new AutoPlay({ duration: 2000, stopOnHover: true });
  const plugins = [
    autoplayPlugin,
    new Perspective({ rotate: 0, scale: 0.3, perspective: 400 }),
    new Arrow({
      nextElSelector: `.${styles.nextButton}`,
      prevElSelector: `.${styles.previousButton}`,
    }),
  ];

  const stopAutoPlay = () => {
    autoplayPlugin.destroy();
  };

  const prevIndicatorClasses = classNames(
    styles.indicator,
    styles.previousIndicator
  );
  const nextIndicatorClasses = classNames(
    styles.indicator,
    styles.nextIndicator
  );

  const prevBtnClasses = classNames(styles.button, styles.previousButton);
  const nextBtnClasses = classNames(styles.button, styles.nextButton);

  return (
    <Flicking
      ref={flickingRef}
      circular={true}
      plugins={plugins}
      cameraClass={styles.camera}
      gap={0}
      align="center"
      onHoldStart={stopAutoPlay}
      onSelect={stopAutoPlay}
    >
      {items.map(({ name, imageUrl }, idx) => (
        <div key={idx}>
          <BurgerCarouselItem title={name} imageUrl={imageUrl} />
        </div>
      ))}
      <ViewportSlot>
        <div className={styles.controls}>
          <div className={prevIndicatorClasses}>
            <span className={prevBtnClasses} onClick={stopAutoPlay}>
              {/* <Icon icon="chevron" height={12} width={10} /> */}
              <ChevronIcon height={16} width={16} />
            </span>
          </div>
          <div className={nextIndicatorClasses}>
            <span className={nextBtnClasses} onClick={stopAutoPlay}>
              {/* <Icon icon="chevron" height={12} width={10} /> */}
              <ChevronIcon />
            </span>
          </div>
        </div>
      </ViewportSlot>
    </Flicking>
  );
};

export default PromotionalCarousel;
