import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/epic-feeds-01.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/epic-feeds-02.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/epic-feeds-03.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/epic-feeds-04.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/happiness_slider/RE-Burger-Slider-L-01.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/happiness_slider/RE-Burger-Slider-L-02.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/happiness_slider/RE-Burger-Slider-L-03.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/happiness_slider/RE-Burger-Slider-L-04.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/happiness_slider/RE-Burger-Slider-L-05.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/happiness_slider/RE-Burger-Slider-L-06.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/happiness_slider/RE-Burger-Slider-P-01.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/happiness_slider/RE-Burger-Slider-P-02.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/happiness_slider/RE-Burger-Slider-P-03.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/happiness_slider/RE-Burger-Slider-P-04.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/happiness_slider/RE-Burger-Slider-P-05.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/happiness_slider/RE-Burger-Slider-P-06.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/join-us-01.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/join-us-02.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/join-us-03.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/join-us-04.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/nom-nom-nom.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/about/ScrollToSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/home-components/happiness-by-the-handful/HappinessByTheHandful.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/home-components/epic-feeds/EpicFeeds.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/home-components/join-the-family/JoinTheFamilyParallax.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/home-components/nom-nom-nom/NomNomNom.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/home-components/nom-nom-nom/NomNomNomParallax.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/home-components/whats-cooking/burger-carousel/BurgerCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/full-page-slider/FullPageSlider.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/container/Container.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/button/Button.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/home-components/whats-cooking/burger-carousel/BurgerCarouselLoading.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/home-components/whats-cooking/burger-carousel/BurgerCarousel.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/home-components/whats-cooking/burger-carousel/BurgerCarouselItem.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/home-components/whats-cooking/WhatsCookingAction.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/home-components/whats-cooking/WhatsCooking.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/home-components/join-the-family/JoinTheFamily.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/full-page-slider/FullPageTransitionSlides.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/full-page-slider/ScrollButton.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/image-container/ImageContainer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/main/Main.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/parallax-section/ParallaxSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/section/Section.module.scss");
