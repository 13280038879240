"use client";
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./FullPageSlider.module.scss";
import useScrollPosition from "@/hooks/useScrollPosition";

const ScrollButton = () => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(true);
  }, []);
  const scrollPosition = useScrollPosition();

  const maxScrollDistance = 300;
  const baseOpacity = 1;
  const fadeOutRate = scrollPosition / maxScrollDistance;
  const opacity = Math.max(baseOpacity - fadeOutRate, 0);

  const handleScroll = () => {
    const targetElement = document.getElementById("epic-feeds");
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  console.log(ready);

  return (
    <div
      onClick={handleScroll}
      className={styles.scrollButton}
      style={{ opacity: opacity }}
    >
      <div className={styles.scrollButtonLine}>
        <div
          className={classNames(styles.scrollButtonBall, {
            [styles.scrollButtonBallAnimate]: ready,
          })}
        ></div>
      </div>
    </div>
  );
};

export default ScrollButton;
