"use client";
import React, { ReactNode, useEffect, useRef } from "react";
import styles from "./ParallaxSection.module.scss";

interface ParallaxSectionProps {
  backgroundContent: ReactNode;
  foregroundContent: ReactNode;
  height?: string;
}

const ParallaxSection: React.FC<ParallaxSectionProps> = ({
  backgroundContent,
  foregroundContent,
  height = "100vh",
}) => {
  const backgroundRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (backgroundRef.current) {
        const { top } = backgroundRef.current.getBoundingClientRect();
        const speed = 0.1;
        backgroundRef.current.style.transform = `translateY(${top * speed}px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className={styles.parallax} style={{ height }} id="epic-feeds">
      <div ref={backgroundRef} className={styles.background}>
        {backgroundContent}
      </div>
      <div className={styles.foreground}>{foregroundContent}</div>
    </section>
  );
};

export default ParallaxSection;
