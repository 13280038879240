"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";

const ScrollToSection = () => {
  const pathname = usePathname();

  const handleHashScroll = () => {
    const { hash } = window.location;
    if (hash) {
      const element = document.querySelector(`[data-scroll-section="${hash}"]`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    handleHashScroll();
    window.addEventListener("hashchange", handleHashScroll);

    return () => {
      window.removeEventListener("hashchange", handleHashScroll);
    };
  }, [pathname]);

  return <></>;
};

export default ScrollToSection;
