"use client";

import React, { useState, useEffect } from "react";
import classNames from "classnames";

import styles from "./FullPageSlider.module.scss";

import Image, { StaticImageData } from "next/image";

import { PlaceholderValue } from "../image-container/ImageContainer";

export interface ImageSet {
  landscape: string | StaticImageData;
  portrait: string | StaticImageData;
}

interface FullPageTransitionSlidesProps {
  imageSet: ImageSet[];
  priorityStrategy?: "All" | "first" | "none";
  placeholder?: PlaceholderValue | undefined;
}

const SLIDE_INTERVAL_MS = 3500;

const FullPageTransitionSlides: React.FC<FullPageTransitionSlidesProps> = ({
  imageSet,
  priorityStrategy,
  placeholder,
}) => {
  const [ready, setReady] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageSet.length);
    }, SLIDE_INTERVAL_MS);

    return () => clearInterval(interval);
  }, [imageSet]);

  return (
    <div className={styles.imageTransitionsContainer}>
      {imageSet.map((set, index) => {
        const priority =
          priorityStrategy === "All" ||
          (priorityStrategy === "first" && index === 0);

        const isStaticImageData = (
          img: string | StaticImageData
        ): img is StaticImageData => {
          return (img as StaticImageData).src !== undefined;
        };

        return (
          <picture key={index}>
            <source
              media="(min-width: 768px)"
              srcSet={
                isStaticImageData(set.landscape)
                  ? set.landscape.src
                  : set.landscape
              }
            />
            <Image
              src={set.portrait}
              alt={`Image ${index + 1}`}
              className={classNames(styles.image, {
                [styles.active]: index === currentImageIndex,
                [styles.zoom]: index === currentImageIndex && ready,
              })}
              style={{
                zIndex: imageSet.length - index,
              }}
              fill
              priority={priority}
              placeholder={placeholder}
            />
          </picture>
        );
      })}
    </div>
  );
};

export default FullPageTransitionSlides;
